import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import MissionDailyRewardCard from './components/MissionDailyRewardCard/MissionDailyRewardCard'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { Analysis } from '../../../services/analysisService'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'
import { store } from '../../../store/configureStore'
import { getMissionDailyRewardGameEngine } from '../../../services/rewardsService'
import {
  setCoinsAccumulated,
  setDailyAccessReward
} from '../../../store/RewardsSlice/RewardsSlice'

const MetaTabMissions = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingClaim, setIsLoadingClaim] = useState(false)
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)
  const amountDailyReward = useSelector(
    (state) => state.rewards.amountDailyReward
  )
  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Missions Page Viewed']
    )

    setIsLoading(false)
  }, [])

  const handleGetDailyReward = async (cooldownHours) => {
    setIsLoadingClaim(true)
    try {
      // await fetcher(createEndPoint('organization')).then((json) => json.data)
      const missionDailyRewardResponse = await getMissionDailyRewardGameEngine()
      const wasRewardClaimed =
        !missionDailyRewardResponse?.data?.error &&
        missionDailyRewardResponse?.data?.userClaimableReward
      const nextClaimTime = missionDailyRewardResponse?.data?.nextClaimTime

      if (nextClaimTime) {
        if (wasRewardClaimed) {
          const coinsAfterReward = coinsAccumulated + amountDailyReward

          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Daily Reward Collected'],
            {
              coins_earned: amountDailyReward,
              coins_acumulated: coinsAfterReward
            }
          )

          store.dispatch(setCoinsAccumulated(coinsAfterReward))
        }
        // Actualizar los valores que cambian para 'daily reward'
        store.dispatch(
          setDailyAccessReward({
            canGetDailyReward: false,
            nextDailyReward: nextClaimTime,
            amountDailyReward: amountDailyReward,
            cooldownHours: cooldownHours
          })
        )
      }
    } finally {
      setIsLoadingClaim(false)
    }
    /*  */
  }

  return (
    <div className="meta-popup-selector">
      {renderSoundFXController()}
      <div className="meta-popup-selector__header">
        <div className="meta-popup-selector__header-top">
          <div className="meta-popup-selector__header-left">
            <div
              className={`meta-popup-selector__header-left__content
                'meta-popup-selector__header-left__content-big'
              }`}
            >
              <div className="meta-popup-selector__header-left__content-coins">
                <div className="meta-popup-selector__header-left__content-coins-text__misions">
                  {coinsAccumulated}
                </div>
                <div className="meta-popup-selector__header-left__content-coins-icon">
                  <CoinSvg />
                </div>
              </div>
            </div>
          </div>

          <div className="meta-popup-selector__header-center">
            <div className="meta-popup-selector__title">
              {translate('missions_title')}
            </div>
          </div>

          <div className="meta-popup-selector__header-right">
            <div className="meta-popup-selector__header-right__content-empty"></div>
          </div>
        </div>
      </div>
      <div className="-meta-tab-missions__content">
        {!isLoading && (
          <MissionDailyRewardCard
            onClickGetReward={(cooldownHours) => {
              playSoundEffect('collectCoins')
              handleGetDailyReward(cooldownHours)
            }}
            isLoading={isLoadingClaim}
          />
        )}
      </div>
    </div>
  )
}

export default MetaTabMissions
