import React, { useEffect, useState } from 'react'
import translate from '../../../../../i18n/translate'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import { ReactComponent as MessageFrameSvg } from '../../../../../assets/img/metaberry_ui/information-frame.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import {
  getSoundAssistance,
  getPanelRenderCount,
  updatePanelRenderCount
} from '../../../../../services/settingsService'
import {
  setMoveCameraToPlayer,
  setMoveCameraToObject
} from '../../../../../store/MetaberrySlice/MetaberrySlice'
import { Analysis } from '../../../../../services/analysisService'
import { store } from '../../../../../store/configureStore'
import { useSelector } from 'react-redux'
import PopupWrapped from '../../PopupWrapped'

const OnboardingUnlockedLesson = ({ handleCheck, pathSchool }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onboardingFirstBatteryUnblocked_' + langCode
  const [blueberroImage, setBlueberroImage] = useState()
  const [isPopupVisible, setIsPopupVisible] = useState(false)

  useEffect(() => {
    // Hasta que termine el movimiento de cámara el popup ha de ser invisible
    const parent = document.querySelector('.popup-manager')
    if (parent) parent.className += ' popup-manager-transparent'

    updatePanelRenderCount(getPanelRenderCount() + 1)

    store.dispatch(setMoveCameraToObject({ active: true }))

    return () => {
      if (parent) {
        parent.className = parent.className.replace(
          ' popup-manager-transparent',
          ''
        )
      }

      updatePanelRenderCount(getPanelRenderCount() - 1)
    }
  }, [])

  useSelector((state) => {
    const moveCameraToObjectIndex = state.metaberry.moveCameraToObjectIndex

    if (moveCameraToObjectIndex === -1 && !isPopupVisible) {
      const parent = document.querySelector('.popup-manager')
      if (parent) parent.className = 'popup-manager'

      setIsPopupVisible(true)

      getSoundAssistance() && playSoundEffect(audioFile)
    }
  })

  const onClose = () => {
    handleCheck()

    // Mover al personaje
    store.dispatch(setMoveCameraToPlayer(true))
  }

  useEffect(() => {
    setBlueberroImage(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/lucas-lock.svg`
        : `/assets/graphics/gamification/bb-lock.svg`
    )
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_unlocked_lesson',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  return (
    <>
      <PopupWrapped>
        <div className="onboarding-popup__backdrop" onClick={onClose}></div>
        <div className="onboarding-popup__container-with-image centered">
          <div className="onboarding-popup__close-icon-new" onClick={onClose}>
            <CloseIcon />
          </div>
          <div className="onboarding-popup__bb-image-container">
            <img
              className="onboarding-popup__bb-image"
              src={blueberroImage}
              alt=""
            />
          </div>

          <div className="onboarding-popup__text-container">
            <MessageFrameSvg
              preserveAspectRatio="none"
              className="onboarding-popup__text-little-background"
            />
            <p className="onboarding-popup__little-text">
              {translate('onboarding_first_lesson_text')}
            </p>
            <div className="onboarding-popup__little-audio">
              {renderSoundFXController()}
              <AudioBtn
                onClick={() => {
                  handleAudio()
                  playSoundEffect(audioFile)
                }}
              />
            </div>
          </div>

          <div className="onboarding-panel__bottom"></div>
        </div>
      </PopupWrapped>
    </>
  )
}
export default OnboardingUnlockedLesson
