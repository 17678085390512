import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './titleContainer.scss'
import Title from '../Title/Title'
import { ReactComponent as AudioButton } from '../../../assets/img/metaberry_ui/sound_on.svg'

const TitleContainer = ({
  titleText,
  level,
  message,
  batteryIcon,
  completeTitle,
  coins,
  audioFile,
  playSoundEffect,
  className
}) => {
  const renderMessage = () => {
    if (!message) return null
    return (
      <div
        className="information-popup_message-text"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    )
  }

  return (
    <div className={`information-popup_message-wrapper-out ${className ?? ''}`}>
      <div className={`information-popup_message-wrapper-in `}>
        <div className="title-container-wrapper">
          <Title text={titleText} />
        </div>

        {level && renderMessage()}

        {batteryIcon && <Title text={completeTitle} />}

        {coins && renderMessage()}

        {audioFile && (
          <AudioButton
            className="information-popup_message-audio-button"
            onClick={() => playSoundEffect(audioFile)}
          />
        )}
      </div>
    </div>
  )
}

TitleContainer.propTypes = {
  titleText: PropTypes.string.isRequired,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.string,
  batteryIcon: PropTypes.bool,
  completeTitle: PropTypes.string,
  coins: PropTypes.number,
  audioFile: PropTypes.string,
  playSoundEffect: PropTypes.func,
  className: PropTypes.string
}

export default memo(TitleContainer)
