import React, { useEffect, useState } from 'react'
import PracticeView from './modules/views/PracticeView/PracticeView'
import PracticeCompleteView from './modules/views/PracticeCompleteView/PracticeCompleteView'
import {
  setAppHostReactNative,
  setAppTokenReactNative
} from './services/practiceServicesReactNative'
import { store } from '../../store/configureStore'
import { updateDevMode } from '../../store/Configuration/Configuration'
import { setCustomLanguageCode, initLang } from '../../i18n/initI18n'
import ActivityLoading from './modules/components/ActivityLoading/ActivityLoading'
import translate from '../../i18n/translate'
import { useGamificationActivityContext } from '../../context/GamificationActivityContext/useGamificationActivityContext'
import { CURRENCY_TYPES } from '../../config/constants/GamificationConstants'
import { setCourse } from '../../store/Course/Course'
// import { testLocalAppPractice1, testLocalAppPractice2 } from './testLocalAppPractice'

const AppPracticePage = () => {
  const PRACTICE_STATES = ['LOADING', 'PRACTICING', 'COMPLETED']
  const [practiceState, setPracticeState] = useState(0)
  const [practiceBaseData, setPracticeBaseData] = useState(null)
  const { pointsReward, currencyReward, setResetActivityGamification } =
    useGamificationActivityContext()
  const [practiceProgressData, setPracticeProgressData] = useState({
    sessionId: undefined,
    earnedExperience: pointsReward,
    earnedExtraExperience: 0,
    earnedPieces: [0, 0, 0],
    earnedCoins: 0
  })
  const [isIphone, setIsIphone] = useState(false)

  useEffect(() => {
    setPracticeProgressData({
      ...practiceProgressData,
      earnedExperience: pointsReward
    })
  }, [pointsReward])

  useEffect(() => {
    setPracticeProgressData({
      ...practiceProgressData,
      earnedCoins: currencyReward[CURRENCY_TYPES.COINS]
    })
  }, [currencyReward[CURRENCY_TYPES.COINS]])

  useEffect(() => {
    setIsIphone(/iPad|iPhone|iPod/.test(window.navigator.userAgent))

    window.addEventListener('message', messageFromReactNative)
    document.addEventListener('message', messageFromReactNative)

    messageToReactNative({ function: 'onReady' })

    // Pruebas local ------------------------------ (inicio)
    /* eslint-disable */
    if (false) {
      // testLocalAppPractice1()
    }
    // Para Backoffice
    if (false) {
      // testLocalAppPractice2()      
    }
    /* eslint-enable */
    // Pruebas local -------------------------------------------- (final)

    return () => {
      window.removeEventListener('message', messageFromReactNative)
      document.removeEventListener('message', messageFromReactNative)
    }
  }, [])

  const messageFromReactNative = (event) => {
    try {
      const dataFromRN = JSON.parse(event.data)

      if (
        dataFromRN?.function === 'startPractice' ||
        dataFromRN?.function === 'startPracticeWeb'
      ) {
        const paramsRN = dataFromRN.params

        setAppHostReactNative(paramsRN.apiHost) // Establecer host
        setAppTokenReactNative(paramsRN.userToken) // Establecer userToken

        if (paramsRN.userDevMode !== undefined) {
          store.dispatch(
            updateDevMode({ isDevMode: paramsRN.userDevMode === 1 })
          )
        }

        // Establecer datos base de la práctica
        const usableChallenge =
          paramsRN.lesson.status === 'broked_start' &&
          paramsRN.lesson.challenges[1]
            ? paramsRN.lesson.challenges[1]
            : paramsRN.lesson.challenges[0]

        setPracticeBaseData({
          courseGuid: paramsRN.courseGuid,
          lessonGuid: paramsRN.lesson.lesson_guid,
          unitGuid: paramsRN.lesson.unit_guid,
          lessonChallenge: usableChallenge.name,
          lessonChallengeExp: usableChallenge.exp,
          lessonStatus: paramsRN.lesson.status,
          lessonPiecesHave: paramsRN.lesson.pieces.have,
          lessonPiecesTotal: paramsRN.lesson.pieces.pieces,
          earnedCoins: paramsRN.lesson.challenge_extra_effort_coins,
          language: paramsRN.language,
          requiresAudioAssistance: paramsRN.requiresAudioAssistance,
          statusBarHeight: paramsRN?.statusBarHeight || null,
          userEmail: paramsRN?.user?.email,
          username: paramsRN?.user?.username,
          userGuid: paramsRN?.user?.guid,
          isFromApp: dataFromRN?.function === 'startPractice'
        })

        // ocLog('ini challenge', usableChallenge.name)
        // ocLog('ini status', paramsRN.lesson.status)

        // Se usa el idioma que informa ReactNative
        // Sólo desde App, en Web lo coge del navegador y del programa
        if (paramsRN.language && dataFromRN.function !== 'startPracticeWeb') {
          setCustomLanguageCode(paramsRN.language.toLowerCase())
          initLang()
        }

        // Se usa el 'educational_internal_level' que informa ReactNative
        // Sólo desde App, en Web se almacena al recoger los datos del curso
        if (
          paramsRN.educational_internal_level &&
          dataFromRN.function !== 'startPracticeWeb'
        ) {
          store.dispatch(
            setCourse({
              educational_internal_level: paramsRN.educational_internal_level
            })
          )
        }

        setPracticeState(1)
      } else if (dataFromRN?.function === 'loadActivityPreview') {
        setPracticeBaseData(dataFromRN.params)
        setPracticeState(1)
      }
    } catch (e) {}
  }

  const messageToReactNative = (message) => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
    } else {
      window.postMessage(JSON.stringify(message))
      window?.parent && window.parent.postMessage(JSON.stringify(message), '*')
    }
  }

  return (
    <div className="app-practice-page">
      {PRACTICE_STATES[practiceState] === 'LOADING' && (
        <ActivityLoading
          title={translate('app_practice_loading_title')}
          message={translate('app_practice_loading_message')}
        />
      )}

      {PRACTICE_STATES[practiceState] === 'PRACTICING' && (
        <PracticeView
          practiceBaseData={practiceBaseData}
          setPracticeBaseData={setPracticeBaseData}
          practiceProgressData={practiceProgressData}
          setPracticeProgressData={setPracticeProgressData}
          practiceState={practiceState}
          setPracticeState={setPracticeState}
          messageToReactNative={messageToReactNative}
          isIphone={isIphone}
          pointsReward={pointsReward}
          setResetActivityGamification={setResetActivityGamification}
        />
      )}

      {PRACTICE_STATES[practiceState] === 'COMPLETED' && (
        <PracticeCompleteView
          practiceBaseData={practiceBaseData}
          setPracticeBaseData={setPracticeBaseData}
          practiceProgressData={practiceProgressData}
          setPracticeProgressData={setPracticeProgressData}
          setPracticeState={setPracticeState}
          messageToReactNative={messageToReactNative}
          isIphone={isIphone}
          pointsReward={pointsReward}
        />
      )}
    </div>
  )
}

export default AppPracticePage
