import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import React, { useState } from 'react'

export default function usePostHogService() {
  const [isPostHogInitialized, setIsPostHogInitialized] = useState(false)

  const initializePostHog = (isDemo = true) => {
    if (!isPostHogInitialized) {
      posthog.opt_in_capturing()

      posthog.init(
        window.isProductionEnvironment
          ? 'phc_3uRym0rwkVA7zbXCz5efpgyzXNdtdU7j3JqgFSQb2ag'
          : 'phc_50dWocuhiGdo6M0t2pAl5Pwh7SzGFinvVJRZuPx0RsN',
        {
          api_host: 'https://app.posthog.com',
          autocapture: !isDemo,
          disable_session_recording:
            window.isProductionEnvironment && Boolean(isDemo),
          person_profiles: 'identified_only'
        }
      )
      setIsPostHogInitialized(true)
    }
  }

  const disablePosthog = () => {
    posthog.reset()
    posthog.opt_out_capturing()

    setIsPostHogInitialized(false)
  }

  return { initializePostHog, isPostHogInitialized, disablePosthog }
}

function PostHogServiceProvider(props) {
  return <PostHogProvider client={posthog} {...props} />
}

function identifyPosthog(userId, userData) {
  posthog.identify(userId, userData)
}

function resetPosthog() {
  posthog.reset()
}

export { PostHogServiceProvider, identifyPosthog, resetPosthog }
