import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import translate from '../../i18n/translate'

import { ReactComponent as LogoSimple } from '../../assets/img/logotipo_morado.svg'
import BBCoverImg from '../../assets/img/bg-login.png'
import { ReactComponent as OCLogo } from '../../assets/img/oc-logo.svg'
import { ReactComponent as BackIcon } from '../../assets/icons/arrow-circle-left.svg'
import FormButton from '../BlueberryFamilyPages/components/FormButton/FormButton'
import PasswordForm from './components/PasswordForm/PasswordForm'
import ChangeSuccess from './components/ChangeSuccess/ChangeSuccess'
import './recoverPasswordPage.scss'
import { recoverAccountPassword } from '../../services/userManagementService'
import { useIsMobile } from '../../hooks/useIsMobile'

function LogoBB() {
  return (
    <div className="recover-password-page__bb-logo-container">
      <LogoSimple
        className="recover-password-page__bb-logo"
        alt="Blueberry Math"
      />
    </div>
  )
}

function RecoverPasswordChangePage() {
  const isMobile = useIsMobile()
  const MIN_PASSWORD_CHARACTERS = 8

  const ERROR_MESSAGES = {
    PASSWORD_CHARACTERS: translate(
      'recover_password_error_password_characters'
    ),
    PASSWORD_NOT_MATCHING: translate(
      'family_my_profile_error_passwords_not_matching'
    )
  }

  const history = useHistory()
  const params = useParams()
  const [recoverToken, setRecoverToken] = useState('')

  useEffect(() => {
    setRecoverToken(params.token)
  }, [])

  let sendingTimeout = null

  const [isWorking, setIsWorking] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isChangeSuccess, setIsChangeSuccess] = useState(false)
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('')
  const [newPasswordCopyErrorMessage, setNewPasswordCopyErrorMessage] =
    useState('')

  const goToLogin = useCallback(() => {
    history.push(`/login`)
  }, [history])

  const validatePasswordFormat = (newPassword, newPasswordCopy) => {
    if (newPassword.length > 0)
      if (newPassword.length < MIN_PASSWORD_CHARACTERS) {
        setNewPasswordErrorMessage(ERROR_MESSAGES.PASSWORD_CHARACTERS)
      } else {
        setNewPasswordErrorMessage('')
      }

    if (newPasswordCopy.length > 0)
      if (newPasswordCopy.length < MIN_PASSWORD_CHARACTERS) {
        setNewPasswordCopyErrorMessage(ERROR_MESSAGES.PASSWORD_CHARACTERS)
      } else {
        if (newPassword !== newPasswordCopy) {
          setNewPasswordCopyErrorMessage(ERROR_MESSAGES.PASSWORD_NOT_MATCHING)
        } else setNewPasswordCopyErrorMessage('')
      }

    setNewPassword(newPassword)
  }

  const onSave = () => {
    setIsWorking(true)
    clearTimeout(sendingTimeout)

    sendingTimeout = setTimeout(async () => {
      const recoverAccountPasswordResponse = await recoverAccountPassword(
        recoverToken,
        newPassword
      )

      if (recoverAccountPasswordResponse.status === 'success') {
        setIsChangeSuccess(true)
      }

      setIsWorking(false)
    }, 250)
  }

  return (
    <div className="recover-password-page">
      {!isMobile && (
        <>
          <img
            className="recover-password-page__cover-img"
            src={BBCoverImg}
            alt="Blueberry background"
          />
          <img
            className="recover-password-page_rocket"
            src="/assets/graphics/images/rocket.png"
            alt="Rocket"
          />
        </>
      )}
      <div className="recover-password-page-container">
        <div className="recover-password-page__content">
          {isMobile && (
            <div className="recover-password-page_header-mobile">
              <LogoBB />
            </div>
          )}
          <img
            className="recover-password-page_luna"
            src="/assets/graphics/images/luna.png"
            alt="Luna"
          />
          {isMobile && (
            <img
              className="recover-password-page_rocket"
              src="/assets/graphics/images/rocket.png"
              alt="Rocket"
            />
          )}
          {!isMobile && <LogoBB />}

          <div className="recover-password-page__form-content">
            {!isChangeSuccess ? (
              <PasswordForm
                onSubmit={onSave}
                newPasswordError={newPasswordErrorMessage}
                newPasswordCopyError={newPasswordCopyErrorMessage}
                validatePasswordFormat={validatePasswordFormat}
                disabled={isWorking}
              />
            ) : (
              <ChangeSuccess onButtonClick={goToLogin} />
            )}
          </div>

          <div className="recover-password-page__footer">
            {!isChangeSuccess && (
              <FormButton
                icon={<BackIcon />}
                text={translate('recover_password_back')}
                variant="transparent"
                textAlign="left"
                onClick={goToLogin}
              />
            )}
            <div className="recover-password-page__oc-logo">
              <OCLogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecoverPasswordChangePage
