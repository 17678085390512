import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/react'
import packageJson from '../package.json'
import { store } from './store/configureStore'
import Routes from './pages/Routes'
import '@oneclick/react-lemonade-editor/dist/index.css'
import './theme/index.scss'
import 'simplebar/dist/simplebar.min.css'
import { initLang } from './i18n/initI18n'
import { loadPolyfills } from './polyfill/globalPolyfill'
import { loadCreateImageBitmapPolyfill } from './polyfill/createImageBitmapPollyfill'
import { PostHogServiceProvider } from './services/usePosthogService'
// import * as serviceWorker from './serviceWorker'

loadPolyfills()
loadCreateImageBitmapPolyfill()

// Hay errores al reproducir sonidos especialemnte en Safari
// Como no hemos podido reproducirlo, y aparentemente no bloquea a los usuarios
// con esto se trata de silenciarlo en Sentry
window.addEventListener('error', (event) => {
  const errorMessage = event?.error?.message ? event.error.message : ''
  if (
    event?.error?.name === 'InvalidStateError' &&
    errorMessage.includes('Failed to start the audio device')
  ) {
    console.error('Failed to start the audio device: ', event.error)
    event.stopPropagation()
  }
})

// deactivates ctrl+/- and wheel mouse zoom
document.addEventListener('keydown', function (e) {
  if (
    e.ctrlKey &&
    (e.key === 'Add' || e.key === 'Subtract' || e.key === '+' || e.key === '-')
  ) {
    e.preventDefault()
  }
})

document.addEventListener(
  'wheel',
  function (e) {
    if (e.ctrlKey) {
      e.preventDefault()
    }
  },
  {
    passive: false
  }
)

async function initApp() {
  console.log(`BB v.${packageJson.version} - 2025.01.23`)
  ocLog(window.location.hostname)

  const isLemonadePreview =
    window.location.pathname.includes('__lemonade-preview')

  if (window.isProductionEnvironment && !isLemonadePreview) {
    Sentry.init({
      dsn: 'https://738cb3e506abe3d2b8490c141e41b5c2@o4505759437029376.ingest.sentry.io/4505759456624640',

      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: true,
          blockAllMedia: false
        }),

        Sentry.replayCanvasIntegration(),

        Sentry.browserTracingIntegration()
      ]

      /* ,
      tracePropagationTargets: [
        '^(https?://)?([a-zA-Z0-9-]+.)*blueberrymath.ai(/.*)?$',
        '^(https?://)?([a-zA-Z0-9-]+.)*blueberry-sa.oneclicklabs.es(/.*)?$',
        '^(https?://)?([a-zA-Z0-9-]+.)*blueberry-sa.es(/.*)?$',
        '^(https?://)?([a-zA-Z0-9-]+.)*oneclick.es(/.*)?$',
        '^(https?://)?([a-zA-Z0-9-]+.)*blueberry.app.br(/.*)?$'
      ] */
    })
  }

  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <PostHogServiceProvider>
        <Routes />
      </PostHogServiceProvider>
    </Provider>
  )
}

initLang().then(initApp).catch(initApp)

window.addEventListener('unhandledrejection', function (event) {
  console.warn('UNHANDLED PROMISE REJECTION:', event.reason)
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
