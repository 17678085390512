import Phaser from 'phaser'
import Bar from './Bar'
import translate from '../../../../i18n/translate'
import { getUnitProgressPercentAndColor } from '../../../../helpers/calculateUnitProgress'
import { store } from '../../../../store/configureStore'
import checkUnitAvailable from '../../../../util/checkUnitAvailable'

const INFORMATION_DEPTH = 11
const BAR_RADIUS = 7
const BAR_BACK_COLOR = '#939DB0'
const MINIMUN_FONT_SIZE = 8
const BLOCKED_TEXT_COLOR = '#939DB0'
const TOP_RANKER_NAME_COLOR = '#ffffff'
const TOP_RANKER_NAME_MAX_CHARS = 19
const TOP_RANKER_NAME_FONT_FAMILY = 'Inter'
const DEFAULT_FONT = {
  fontFamily: 'Quicksand',
  fontStyle: 'bold',
  fontSize: '12px',
  color: '#000000',
  align: 'center'
}

// En el JSON se indica la tile top-left de donde irá el cartel (2x2 tiles)

export default class UnitInformation {
  constructor(
    scene,
    tileSize,
    frameX,
    frameY,
    mapMarginX,
    mapMarginY,
    unitTitle,
    lessonsTotal,
    completeLessonsTotal,
    isAvailable,
    publicationState,
    index,
    unitGuid
  ) {
    this.scene = scene

    this.tileSize = tileSize

    this.fullAreaX = frameX + mapMarginX
    this.fullAreaY = frameY + mapMarginY
    this.mapMarginX = mapMarginX
    this.mapMarginY = mapMarginY
    this.fullAreaWidth = tileSize * 2
    this.fullAreaHeight = tileSize + tileSize / 4

    this.unitTitle = unitTitle

    this.percentAndColor = getUnitProgressPercentAndColor(
      completeLessonsTotal,
      lessonsTotal
    )
    this.isAvailable = checkUnitAvailable({ isAvailable, publicationState })
    this.index = index

    const topRanker = this.getTopRanker(unitGuid)
    this.topRankerFullname = topRanker?.fullName
    this.topRankerAvatarUrl = topRanker?.avatar

    this.textAreaX = this.fullAreaX
    this.textAreaWidth = this.tileSize * 2
    this.progressBarX = this.fullAreaX + this.tileSize * 0.31
    this.progressBarWidth = this.tileSize * 1.38
    this.progressBarHeight = this.tileSize * 0.11

    if (!isAvailable) {
      // No disponible
      this.textAreaY = this.fullAreaY + this.tileSize * 1.1
      this.textAreaHeight = this.tileSize * 0.57
      this.progressBarY = this.fullAreaY + this.tileSize
    } else if (this.topRankerFullname) {
      // Disponible CON usuario en ranking
      this.textAreaY = this.fullAreaY + this.tileSize * 0.48
      this.textAreaHeight = this.tileSize * 0.9
      this.progressBarY = this.fullAreaY + this.tileSize * 1.5
    } else {
      // Disponible SIN usuario en ranking
      this.textAreaY = this.fullAreaY + this.tileSize * 0.8
      this.textAreaHeight = this.tileSize * 0.9
      this.progressBarY = this.fullAreaY + this.tileSize * 0.6
    }

    this.unitTitleText = null
    this.unitNumber = null
    this.unitBorder = null
    this.unitProgressBar = null
    this.backStar = null
    this.frontStarBig = null
    this.frontStarSmall = null
    this.topRankerNameText = null
    this.topRankerAvatarImageFailed = false
  }

  getTopRanker(unitGuid) {
    const courseRankers =
      store.getState()?.ranking?.unitsTopRankedAndUserRanking
    const userAndTop = courseRankers?.find((rank) => rank.unitId === unitGuid)
    const topRanker = userAndTop?.topUser
    const fullName =
      topRanker?.name && topRanker?.lastname
        ? `${topRanker.name} ${topRanker.lastname}`
        : `${topRanker?.name || ''}${topRanker?.lastname || ''}`
    const avatar = topRanker?.single_image || ''

    return { fullName, avatar }
  }

  generateInformation() {
    this.drawSign()
    this.drawNumber()
    this.drawName()

    if (this.isAvailable) {
      // Obtener color
      let frontColor = this.percentAndColor.color
      frontColor = Phaser.Display.Color.HexStringToColor(frontColor).color

      this.drawProgressBar(frontColor)
      this.drawBorder(frontColor)
      this.drawDecoration()

      if (this.topRankerFullname) {
        this.drawTopRanker()
      }
    }
  }

  drawSign() {
    if (this.signTop) this.signTop.destroy()

    let signTopImage
    let signBottomImage

    if (this.isAvailable) {
      signTopImage = this.topRankerFullname ? 'sign_top_ranked' : 'sign_top'
      signBottomImage = this.topRankerFullname
        ? 'sign_bottom_ranked'
        : 'sign_bottom'
    } else {
      signTopImage = 'sign_top_lock'
      signBottomImage = 'sign_bottom_lock'
    }

    this.signTop = this.scene.add
      .image(this.fullAreaX, this.fullAreaY + 1, signTopImage, null, {
        label: signTopImage + this.unitTitle,
        isStatic: true
      })
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0)
      .setDepth(INFORMATION_DEPTH)

    if (this.signBottom) this.signBottom.destroy()

    this.signBottom = this.scene.matter.add
      .image(
        this.fullAreaX + this.tileSize,
        this.fullAreaY + this.fullAreaHeight + this.tileSize / 4,
        signBottomImage,
        null,
        { label: signBottomImage + this.unitTitle, isStatic: true }
      )
      .setDisplaySize(this.tileSize * 2, this.tileSize)
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  drawNumber() {
    if (this.unitNumber) this.unitNumber.destroy()

    let numberX = this.fullAreaX + this.tileSize * 1
    let numberY = this.fullAreaY - this.tileSize * 0.24

    if (!this.isAvailable || !this.topRankerFullname) {
      numberX = this.fullAreaX + this.tileSize * 1.02
      numberY = this.fullAreaY + this.tileSize / 4.2
    }

    if (this.isAvailable && this.topRankerFullname) {
      this.signCircle = this.scene.add
        .image(numberX, numberY, 'sign_circle', null, {
          label: 'sign-circle-' + this.unitTitle,
          isStatic: true
        })
        .setDisplaySize(this.tileSize * 0.5, this.tileSize * 0.5)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)
    }

    this.unitNumber = this.getMinimunSizeText(
      this.index + 1,
      {
        ...DEFAULT_FONT,
        fontSize: this.tileSize / 4 + 'px',
        color: this.isAvailable ? '#ffffff' : BLOCKED_TEXT_COLOR
      },
      numberX,
      numberY
    )
  }

  growTextToMaxSize(phaserText, fonSize) {
    const paddingX = this.tileSize * 0.2
    const paddingY = this.tileSize * 0.18
    const maxWidth = this.textAreaWidth - paddingX * 2
    const maxHeight = this.textAreaHeight - paddingY * 2
    let isTooBig = false

    while (!isTooBig) {
      if (phaserText.width > maxWidth || phaserText.height > maxHeight) {
        isTooBig = true
        fonSize -= 0.5
      } else {
        fonSize += 0.5
        phaserText.setFontSize(fonSize + 'px')
      }
    }

    return fonSize
  }

  splitString(inputString, numberOfParts) {
    const words = inputString.split(' ')
    const averageLength = inputString.length / numberOfParts
    const possibleParts = Math.min(words.length, numberOfParts)

    const parts = []
    let part = ''
    let partLength = 0

    words.forEach((word, index) => {
      const nextLength = partLength + word.length + (partLength > 0 ? 1 : 0)

      if (
        Math.abs(averageLength - nextLength) >
          Math.abs(averageLength - partLength - 1) &&
        part.length > 0 &&
        parts.length < possibleParts - 1
      ) {
        parts.push(part)
        part = word
        partLength = word.length
      } else {
        part += (partLength > 0 ? ' ' : '') + word
        partLength += word.length + (partLength > 0 ? 1 : 0)
      }

      if (index === words.length - 1) {
        parts.push(part)
      }
    })

    while (parts.length < numberOfParts) {
      parts.push('')
    }

    return parts.filter((item) => item !== '')
  }

  getMinimunSizeText(text, font, customX, customY) {
    return this.scene.add
      .text(
        customX || this.textAreaX + this.textAreaWidth / 2,
        customY || this.textAreaY + this.textAreaHeight / 2,
        text,
        font
      )
      .setOrigin(0.5)
      .setDepth(INFORMATION_DEPTH)
  }

  drawName() {
    if (this.isAvailable) {
      const isOneLineForced = false

      if (this.unitTitleText) this.unitTitleText.destroy()

      let fontSize1Line = MINIMUN_FONT_SIZE
      const phaserText1Line = this.getMinimunSizeText(this.unitTitle, {
        ...DEFAULT_FONT,
        fontSize: fontSize1Line + 'px'
      })
      fontSize1Line = this.growTextToMaxSize(phaserText1Line, fontSize1Line)

      if (isOneLineForced) {
        this.unitTitleText = phaserText1Line
      } else {
        let fontSize2Lines = MINIMUN_FONT_SIZE
        const text2Lines = this.splitString(this.unitTitle, 2)
        const phaserText2Lines = this.getMinimunSizeText(text2Lines, {
          ...DEFAULT_FONT,
          fontSize: fontSize2Lines + 'px'
        })
        fontSize2Lines = this.growTextToMaxSize(
          phaserText2Lines,
          fontSize2Lines
        )

        let fontSize3Lines = MINIMUN_FONT_SIZE
        const text3Lines = this.splitString(this.unitTitle, 3)
        const phaserText3Lines = this.getMinimunSizeText(text3Lines, {
          ...DEFAULT_FONT,
          fontSize: fontSize3Lines + 'px'
        })
        fontSize3Lines = this.growTextToMaxSize(
          phaserText3Lines,
          fontSize3Lines
        )

        if (
          fontSize3Lines >= fontSize2Lines &&
          fontSize3Lines >= fontSize1Line
        ) {
          phaserText1Line.destroy()
          phaserText2Lines.destroy()
          this.unitTitleText = phaserText3Lines
        } else if (
          fontSize2Lines >= fontSize3Lines &&
          fontSize2Lines >= fontSize1Line
        ) {
          phaserText1Line.destroy()
          phaserText3Lines.destroy()
          this.unitTitleText = phaserText2Lines
        } else {
          phaserText2Lines.destroy()
          phaserText3Lines.destroy()
          this.unitTitleText = phaserText1Line
        }
      }
    } else {
      // Draw blocked text
      this.unitTitleText = this.getMinimunSizeText(
        translate('unit_sign_blocked'),
        {
          ...DEFAULT_FONT,
          fontSize: this.tileSize * 0.25 + 'px',
          color: BLOCKED_TEXT_COLOR
        }
      )
    }
  }

  drawProgressBar(frontColor) {
    if (this.unitProgressBar) this.unitProgressBar.destroy()

    this.unitProgressBar = new Bar(
      this.scene,
      {
        x: this.progressBarX,
        y: this.progressBarY,
        width: this.progressBarWidth,
        height: this.progressBarHeight
      },
      frontColor,
      Phaser.Display.Color.HexStringToColor(BAR_BACK_COLOR).color,
      this.percentAndColor.percent,
      BAR_RADIUS,
      INFORMATION_DEPTH - 1
    )

    this.unitProgressBar.generate()
  }

  drawBorder(frontColor) {
    if (this.isAvailable && this.percentAndColor.percent > 0) {
      if (this.unitBorder) this.unitBorder.destroy()

      const borderSize = Math.max(this.tileSize * 0.075, 1)
      this.unitBorder = this.scene.add.graphics().setDepth(INFORMATION_DEPTH)
      this.unitBorder.lineStyle(borderSize, frontColor)
      this.unitBorder.strokeRoundedRect(
        this.textAreaX + 3,
        this.textAreaY + 3,
        this.textAreaWidth - 6,
        this.textAreaHeight - 6,
        2
      )
    }
  }

  drawDecoration() {
    if (this.percentAndColor.percent >= 100) {
      if (this.backStar) this.unitBorder.destroy()

      this.backStar = this.scene.add
        .image(
          this.fullAreaX + this.tileSize,
          this.fullAreaY + this.tileSize * 0.6,
          'sign_star_back',
          null,
          { label: 'sign_star_back-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 2, this.tileSize * 2)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH - 2)

      if (this.frontStarBig) this.frontStarBig.destroy()

      this.frontStarBig = this.scene.add
        .image(
          this.fullAreaX + this.tileSize * 0.05,
          this.fullAreaY + this.tileSize * 0.74,
          'sign_star_front_big',
          null,
          { label: 'sign_star_front_big-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 0.35, this.tileSize * 0.35)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)

      if (this.frontStarSmall) this.frontStarSmall.destroy()

      this.frontStarSmall = this.scene.add
        .image(
          this.fullAreaX + this.tileSize * 1.8,
          this.fullAreaY + this.tileSize * 1.6,
          'sign_star_front_small',
          null,
          { label: 'sign_star_front_small-' + this.unitTitle, isStatic: true }
        )
        .setDisplaySize(this.tileSize * 0.21, this.tileSize * 0.21)
        .setOrigin(0.5)
        .setDepth(INFORMATION_DEPTH)
    }
  }

  drawTopRanker() {
    if (this.topRankerAvatarUrl) {
      const avatarImageKey = 'top_ranker_avatar_' + this.index

      this.scene.load.image(avatarImageKey, this.topRankerAvatarUrl, {
        headers: { 'Cache-Control': 'no-cache' }
      })

      this.scene.load.once(Phaser.Loader.Events.FILE_LOAD_ERROR, () => {
        this.topRankerAvatarImageFailed = true
      })

      this.scene.load.once(Phaser.Loader.Events.COMPLETE, (e) => {
        if (!this.topRankerAvatarImageFailed) {
          const centerX = this.fullAreaX + this.tileSize * 0.26
          const width = this.tileSize * 0.31
          const avatarWidth = width * 1.2
          const avatarY = this.fullAreaY + this.tileSize * 0.3
          const ellipseMaskY = this.fullAreaY + this.tileSize * 0.29
          const ellipseMaskRadiusY = this.tileSize * 0.28

          // Crear el contenedor primero
          this.topRankerAvatarContainer = this.scene.add
            .container(0, 0)
            .setDepth(INFORMATION_DEPTH + 1)

          // Crear la máscara
          const ellipseForMask = new Phaser.Geom.Ellipse(
            centerX,
            ellipseMaskY,
            width,
            ellipseMaskRadiusY
          )
          this.topRankerAvatarMaskGraphics = this.scene.make
            .graphics()
            .fillEllipseShape(ellipseForMask)
          this.topRankerAvatarMask =
            this.topRankerAvatarMaskGraphics.createGeometryMask()

          // Crear y configurar la imagen
          const avatarImage = this.scene.add.image(
            centerX,
            avatarY,
            avatarImageKey
          )
          const scaleFactor = avatarWidth / avatarImage.width
          avatarImage
            .setDisplaySize(avatarWidth, avatarImage.height * scaleFactor)
            .setOrigin(0.5, 0.5)
            .setMask(this.topRankerAvatarMask)

          this.topRankerAvatarContainer.add(avatarImage)
        }
      })

      this.scene.load.start()
    }

    // Nombre del top ranker
    if (this.topRankerFullname) {
      if (this.topRankerNameText) this.topRankerNameText.destroy()

      let displayName = this.topRankerFullname
      if (displayName.length > TOP_RANKER_NAME_MAX_CHARS) {
        displayName =
          displayName.substring(0, TOP_RANKER_NAME_MAX_CHARS - 1) + '…'
      }

      this.topRankerNameText = this.getMinimunSizeText(
        displayName,
        {
          ...DEFAULT_FONT,
          fontFamily: TOP_RANKER_NAME_FONT_FAMILY,
          fontSize: this.tileSize * 0.14 + 'px',
          color: TOP_RANKER_NAME_COLOR
        },
        this.fullAreaX + this.tileSize * 0.5,
        this.fullAreaY + this.tileSize * 0.27
      ).setOrigin(0, 0.5)
    }
  }

  updateMapMargins(mapMarginX, mapMarginY) {
    try {
      const modX = this.mapMarginX - mapMarginX
      const modY = this.mapMarginY - mapMarginY

      if (this.signCircle) {
        this.signCircle.x -= modX
        this.signCircle.y -= modY
      }

      if (this.signTop) {
        this.signTop.x -= modX
        this.signTop.y -= modY
      }

      if (this.signBottom) {
        this.signBottom.x -= modX
        this.signBottom.y -= modY
      }

      if (this.unitTitleText) {
        this.unitTitleText.x -= modX
        this.unitTitleText.y -= modY
      }

      if (this.unitProgressBar) {
        const backBar = this.unitProgressBar.backgroundBar
        backBar.x -= modX
        backBar.y -= modY

        const foreBar = this.unitProgressBar.foregroundBar
        if (foreBar) {
          foreBar.x -= modX
          foreBar.y -= modY
        }
      }

      if (this.unitNumber) {
        this.unitNumber.x -= modX
        this.unitNumber.y -= modY
      }

      if (this.unitBorder) {
        this.unitBorder.x -= modX
        this.unitBorder.y -= modY
      }

      if (this.topRankerAvatarContainer) {
        this.topRankerAvatarContainer.x -= modX
        this.topRankerAvatarContainer.y -= modY

        if (this.topRankerAvatarMaskGraphics) {
          this.topRankerAvatarMaskGraphics.x -= modX
          this.topRankerAvatarMaskGraphics.y -= modY
        }
      }

      if (this.topRankerNameText) {
        this.topRankerNameText.x -= modX
        this.topRankerNameText.y -= modY
      }

      if (this.backStar) {
        this.backStar.x -= modX
        this.backStar.y -= modY
      }
      if (this.frontStarBig) {
        this.frontStarBig.x -= modX
        this.frontStarBig.y -= modY
      }
      if (this.frontStarSmall) {
        this.frontStarSmall.x -= modX
        this.frontStarSmall.y -= modY
      }

      this.mapMarginX = mapMarginX
      this.mapMarginY = mapMarginY
    } catch (updateMapMarginsError) {
      console.error('UnitInformation updateMapMargins', updateMapMarginsError)
    }
  }

  destroy() {
    if (this.unitTitleText) this.unitTitleText.destroy()
    if (this.unitProgressBar) this.unitProgressBar.destroy()
    if (this.unitNumber) this.unitNumber.destroy()
    if (this.unitBorder) this.unitBorder.destroy()
    if (this.signTop) this.signTop.destroy()
    if (this.signBottom) this.signBottom.destroy()
    if (this.signCircle) this.signCircle.destroy()

    if (this.topRankerAvatarMask) this.topRankerAvatarMask.destroy()
    if (this.topRankerAvatarContainer) this.topRankerAvatarContainer.destroy()
    if (this.topRankerAvatarMaskGraphics)
      this.topRankerAvatarMaskGraphics.destroy()
    if (this.topRankerAvatarMask) this.topRankerAvatarMask.destroy()
    if (this.topRankerNameText) this.topRankerNameText.destroy()

    if (this.backStar) this.backStar.destroy()
    if (this.frontStarBig) this.frontStarBig.destroy()
    if (this.frontStarSmall) this.frontStarSmall.destroy()
  }
}
