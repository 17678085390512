import React, { useEffect, useState, memo } from 'react'
import PropTypes from 'prop-types'
import './title.scss'

const CSS_CLASSES = {
  BOLD_S: 'boldS',
  BOLD_M: 'boldM',
  BOLD_L: 'boldL',
  BOLD_XL: 'boldXL'
}

const TEXT_LENGTH_MAPPING = {
  20: CSS_CLASSES.BOLD_XL,
  31: CSS_CLASSES.BOLD_L,
  40: CSS_CLASSES.BOLD_M
}

function Title({ text, onClassChange }) {
  const [cssClass, setCssClass] = useState(CSS_CLASSES.BOLD_S)

  useEffect(() => {
    if (!text) {
      const newClass = CSS_CLASSES.BOLD_S
      setCssClass(newClass)
      onClassChange?.(newClass)
      return
    }

    const newClass =
      Object.entries(TEXT_LENGTH_MAPPING).find(
        ([length]) => text.length < Number(length)
      )?.[1] || CSS_CLASSES.BOLD_S

    setCssClass(newClass)
    onClassChange?.(newClass)
  }, [text, onClassChange])

  return <div className={cssClass}>{text}</div>
}

Title.propTypes = {
  text: PropTypes.string,
  onClassChange: PropTypes.func
}

export default memo(Title)
