import { createEndPoint, fetcher, getToken, fetchWithToken } from './util'
import { store } from '../store/configureStore'
import {
  setCoinsAccumulated,
  setPetsNotification as setPetsNotificationRedux
} from '../store/RewardsSlice/RewardsSlice'

const rewardsEndPoint = `users/rewards-settings`

export function updateRewardsSettings(rewardsSettings) {
  window.localStorage.setItem('bb_rewards', JSON.stringify(rewardsSettings))

  if (rewardsSettings?.reward_notification !== undefined) {
    store.dispatch(
      setPetsNotificationRedux(rewardsSettings.reward_notification)
    )
  }
}

export function savePetAvatar(currentPetAvatar) {
  let settings = JSON.parse(window.localStorage.getItem('bb_rewards'))

  if (settings) {
    settings.currentPetAvatar = currentPetAvatar
  } else {
    settings = { currentPetAvatar: currentPetAvatar }
  }

  fetcher(createEndPoint(rewardsEndPoint), {
    method: 'PUT',
    body: JSON.stringify({ reward_json_settings: settings }),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  }).then((json) => json.data)

  window.localStorage.setItem('bb_rewards', JSON.stringify(settings))

  // ocLog('SAVING PET AVATAR', currentPetAvatar)
}

export async function buyPetAvatarGamificationEngine(id) {
  const endPoint = `game-engine/rewards-galleries/user/items`
  return fetcher(createEndPoint(endPoint), {
    method: 'POST',
    body: JSON.stringify({
      itemId: id
    }),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  })
    .then((json) => {
      return json
    })
    .catch((json) => {
      return json
    })

  // console.log('BUYING PET AVATAR', guid)
}

export async function updateAccumulatedCoins() {
  const endPoint = `game-engine/info-users`
  const data = await fetchWithToken(createEndPoint(endPoint)).then(
    (json) => json.data
  )
  const coins = data?.currencies?.find(
    (currency) => currency.currencyId === 1
  )?.amount
  store.dispatch(setCoinsAccumulated(coins || 0))

  return coins || 0
}

export function getPetAvatar() {
  const settings = JSON.parse(window.localStorage.getItem('bb_rewards'))
  return settings && settings.currentPetAvatar
    ? settings.currentPetAvatar
    : undefined
}

export function removePetAvatar() {
  savePetAvatar(null)
}

export function setPetsNotification(notificationValue) {
  let settings = JSON.parse(window.localStorage.getItem('bb_rewards'))

  if (settings) {
    settings.reward_notification = notificationValue
  } else {
    settings = { reward_notification: notificationValue }
  }

  fetcher(createEndPoint(rewardsEndPoint), {
    method: 'PUT',
    body: JSON.stringify({ reward_json_settings: settings }),
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  }).then((json) => json.data)

  window.localStorage.setItem('bb_rewards', JSON.stringify(settings))

  store.dispatch(setPetsNotificationRedux(notificationValue))

  // ocLog('SETTING PETS NOTIFICATION', notificationValue)
}

export async function getMissionDailyRewardGameEngine() {
  const dailyRewardEndpoing = createEndPoint(`game-engine/claimable-rewards/1`)

  return await fetcher(dailyRewardEndpoing, {
    method: 'POST',
    headers: {
      Authorization: getToken(),
      'Content-Type': 'application/json'
    }
  })
    .then((data) => data)
    .catch((error) => {
      console.error('Error while claiming daily reward:', error)
    })
}

export async function getPetAvatarsList() {
  const endPoint = `game-engine/rewards-galleries/user/items/status`
  return fetchWithToken(createEndPoint(endPoint)).then((json) => json.data)
}
