import { React, useState } from 'react'
import './ItemDetailPet.scss'
import { ReactComponent as CheckImg } from '../../../../assets/img/button_check.svg'
import CardDetails from '../CardDetails/CardDetails'
import ConfirmPopupOptions from '../../ConfirmPopupOptions/ConfirmPopupOptions'
import translate from '../../../../i18n/translate'
import MapLevelCounter from '../../MapLevelBadge/components/MapLevelCounter/MapLevelCounter'
import MapTabsTooltip from '../../MapTabsTooltip/MapTabsTooltip'
import { ReactComponent as CoinSvg } from '../../../../assets/img/metaberry_ui/coin.svg'
import { useIsMobile } from '../../../../hooks/useIsMobile'

const ItemDetailPet = ({
  isSelected,
  onClickSelect,
  item,
  single_image,
  isIntro,
  isDisabled = true,
  petLevel = 0,
  sizeAvatar = 'm',
  price,
  isPets = false,
  isLocked,
  isOwned = false,
  isExpensive,
  onClickBuy,
  level = 0
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const isMobile = useIsMobile()
  const [showDisabledPopup, setShowDisabledPopup] = useState(false)

  const isAvailable = !isExpensive && !isSelected && !isLocked && !isOwned

  let cardVariantStyle = 'default'
  let onButtonClick = () => {}
  let popupButtonText = ''
  let isPopupButtonDisabled = false

  if (isLocked && !isOwned) {
    cardVariantStyle = 'locked'
    popupButtonText = translate('pets_short_level', { level: petLevel })

    isPopupButtonDisabled = true
  }

  if (isOwned && !isSelected) {
    onButtonClick = () => {
      onClickSelect()
      handleClosePopup()
    }
    popupButtonText = translate('pets_select_pet')
    isPopupButtonDisabled = false
  }

  if (isSelected && isOwned) {
    cardVariantStyle = 'selected'
    popupButtonText = translate('pets_current_pet')
    isPopupButtonDisabled = true
  }

  if (isExpensive && !isOwned) {
    popupButtonText = translate('pets_short_coins')
    isPopupButtonDisabled = true
  }

  if (isAvailable) {
    onButtonClick = onClickBuy
    popupButtonText = translate('pets_adopt_pet')
    isPopupButtonDisabled = false
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  return (
    <>
      <MapTabsTooltip
        width="200px"
        arrowPosition="center"
        message={translate('avatar_short_level', { level: petLevel })}
        isDisabled={isDisabled && !isMobile}
      >
        <div className="item-detail-pet__content-item-detail-pet">
          {!isOwned && (
            <div
              className={
                isDisabled
                  ? 'item-detail-pet__content-header--no-coins'
                  : 'item-detail-pet__content-header'
              }
            >
              <div className="item-detail-pet__content-level">
                <div className="item-detail-pet__content-level-text">
                  {translate('level')}
                </div>
                <MapLevelCounter level={petLevel} />
              </div>
              {price && (
                <div className="item-detail-pet__content__content-coins">
                  <div className="item-detail-pet__content__content-coins-text">
                    {price}
                  </div>
                  <div className="item-detail-pet__content__content-coins-icon">
                    <CoinSvg />
                  </div>
                </div>
              )}
            </div>
          )}

          <div
            className={`item-detail-pet item-detail-pet--default ${
              isSelected && 'item-detail-pet-selected'
            }`}
            onClick={
              isIntro || isDisabled
                ? () => {
                    if (isDisabled && isMobile) {
                      setShowDisabledPopup(true)
                    }
                  }
                : handleOpenPopup
            }
          >
            <div
              className={`item-detail-pet__wrapper item-detail-pet__wrapper--${cardVariantStyle}`}
            >
              {isSelected && !isIntro && (
                <CheckImg className="item-detail-pet__check" />
              )}
              <div
                className={`item-detail-pet__image item-detail-pet__image--default item-detail-pet__image-${sizeAvatar} ${
                  isDisabled ? 'item-detail-pet-img_disabled' : ''
                }`}
              >
                {single_image ? (
                  <img
                    src={single_image}
                    onDragStart={(event) => event.preventDefault()}
                  />
                ) : (
                  <div className="item-detail-pet__sprite"></div>
                )}
              </div>
            </div>
          </div>

          {isPopupOpen && (
            <ConfirmPopupOptions
              aditionalClass="card-preview"
              size="xs"
              acceptButtonText={popupButtonText}
              onAcceptButtonClick={onButtonClick}
              isAcceptButtonDisabled={isPopupButtonDisabled}
              onClose={handleClosePopup}
              showBtnCancel={false}
            >
              <CardDetails
                name={item.name}
                description={item.description}
                image={item.card_image || single_image}
                isSelected={isSelected}
                price={price}
                isLocked={isLocked}
                isExpensive={isExpensive}
                isOwned={isOwned}
              />
            </ConfirmPopupOptions>
          )}

          {showDisabledPopup && (
            <ConfirmPopupOptions
              aditionalClass="card-preview"
              size="xs"
              acceptButtonText={translate('avatar_selected')}
              isAcceptButtonDisabled={true}
              onClose={() => setShowDisabledPopup(false)}
              showBtnCancel={false}
            >
              <CardDetails
                name={item.name}
                description={translate('avatar_short_level', {
                  level: petLevel
                })}
                image={item.card_image || single_image}
                isSelected={isSelected}
                imageClassName="item-detail-img_disabled"
                level={petLevel}
                disabled={true}
              />
            </ConfirmPopupOptions>
          )}
        </div>
      </MapTabsTooltip>
    </>
  )
}

export default ItemDetailPet
