import React from 'react'
import './button.scss'

const MetaButton = ({ text, onClick, isIntro, testId }) => {
  return (
    <div
      className={`meta-popup-selector__button-container ${
        isIntro && 'meta-popup-selector__button-container-intro'
      }`}
    >
      <div
        className="meta-popup-selector__button"
        onClick={onClick}
        data-testid={testId}
      >
        {text}
      </div>
    </div>
  )
}

export default MetaButton
