import { useEffect, useState } from 'react'
import { Analysis } from '../../../services/analysisService'
import { identifyLang } from '../../../i18n/initI18n'
import { getAvatars, saveAvatar } from '../../../services/avatarService'
import { getUserAvatar } from '../../../services/settingsService'

function useMetaTabAvatarSelector() {
  const [isLoading, setIsLoading] = useState(true)
  const [showToast, setShowToast] = useState(false)
  const [avatarsData, setAvatarsData] = useState([])
  const [avatarsTotal, setAvatarsTotal] = useState([])
  const [avatarSelected, setAvatarSelected] = useState([])
  const [avatarPending, setAvatarPending] = useState([])
  const [startSlide, setStartSlide] = useState(0)

  function translateDescription(description) {
    const langCode = identifyLang(navigator.language)
    try {
      const descriptionObject = JSON.parse(description)
      return descriptionObject[langCode]
    } catch (error) {
      return description
    }
  }

  function handleSelectAvatar(item) {
    // Save avatar to profile and set as selected
    saveAvatar(
      item.animation,
      item.single_image,
      item.navigation_icon_image,
      item.sprite_json
    )
    setAvatarSelected(item)

    // Show confirmation toast
    setShowToast(true)
    setTimeout(() => setShowToast(false), 3000)

    // Notify change to game
    window.dispatchEvent(new CustomEvent('player-avatar-change'))

    // Analytics
    const simpleRegexExtractFileNameFromURL = /[^/]*$/

    Analysis.sendEvent(Analysis.EVENT.CHANGE_AVATAR, {
      avatar_animation_url: item.animation,
      avatar_single_url: item.single_image,
      navigation_icon_image: item.navigation_icon_image,
      sprite_json: item.sprite_json,
      avatar_filename: item.animation.match(
        simpleRegexExtractFileNameFromURL
      )[0],
      avatar_name: item.name
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['Select Avatar'], {
      avatar_animation_url: item.animation,
      avatar_single_url: item.single_image,
      navigation_icon_image: item.navigation_icon_image,
      sprite_json: item.sprite_json,
      avatar_filename: item.animation.match(
        simpleRegexExtractFileNameFromURL
      )[0],
      avatar_name: item.name
    })
  }

  async function getData() {
    setIsLoading(true)

    const avatarsDataAPI = await getAvatars()

    // Order by guid so the first avatar is the default and translate
    const avatarsSortedData = avatarsDataAPI
      .map((item) => {
        return {
          guid: item.guid,
          animation: item.image,
          name: item.name,
          description: translateDescription(item.description),
          single_image: item.single_image,
          navigation_icon_image: item.navigation_icon_image,
          sprite_json: item.sprite_json,
          card_image: item.card_image,
          level: item.level
        }
      })
      .sort((a, b) => {
        return a.guid.localeCompare(b.guid)
      })

    const avatarsSortedDataInit = avatarsSortedData
      .filter((avatar) => {
        return avatar.level === 0
      })
      .sort((a, b) => a.level - b.level)
    setAvatarsData(avatarsSortedDataInit)

    // Copia los datos de los avatares 5 veces añadiendo 30 objetos en total
    // const avatarsExtraData = Array(5).fill(avatarsSortedData).flat()
    const avatarsExtraData = avatarsSortedData
      .filter((avatar) => {
        return avatar.level > 0
      })
      .sort((a, b) => a.level - b.level)
    setAvatarsTotal(avatarsExtraData)

    const currentAvatarData = getUserAvatar()
    setAvatarSelected(currentAvatarData)

    if (currentAvatarData) {
      setStartSlide(
        avatarsSortedDataInit.findIndex(
          (avatar) => avatar.single_image === currentAvatarData.single_image
        ) || 0
      )
    }

    setIsLoading(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const setDataAvatarPending = (item) => {
    setAvatarPending(item)
  }

  return {
    handleSelectAvatar,
    avatarsData,
    avatarSelected,
    isLoading,
    showToast,
    setShowToast,
    setDataAvatarPending,
    avatarPending,
    startSlide,
    avatarsTotal
  }
}

export default useMetaTabAvatarSelector
