import { React, useState } from 'react'
import './ItemDetail.scss'
import { ReactComponent as CheckImg } from '../../../../assets/img/button_check.svg'
import CardDetails from '../../MetaTabCommons/CardDetails/CardDetails'
import ConfirmPopupOptions from '../../ConfirmPopupOptions/ConfirmPopupOptions'
import translate from '../../../../i18n/translate'
import MapLevelCounter from '../../MapLevelBadge/components/MapLevelCounter/MapLevelCounter'
import MapTabsTooltip from '../../MapTabsTooltip/MapTabsTooltip'
import { useIsMobile } from '../../../../hooks/useIsMobile'

const ItemDetail = ({
  isSelected,
  onClickSelect,
  avatar,
  isIntro,
  isDisabled = true,
  level = 0
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [showDisabledPopup, setShowDisabledPopup] = useState(false)
  const isMobile = useIsMobile()

  let cardVariantStyle = 'default'
  let onButtonClick = () => {}
  let popupButtonText = ''
  let isPopupButtonDisabled = false

  if (isSelected) {
    cardVariantStyle = 'selected'
    popupButtonText = translate('avatar_selected')
    isPopupButtonDisabled = true
  } else {
    onButtonClick = () => {
      onClickSelect()
      handleClosePopup()
    }
    popupButtonText = translate('avatar_confirm')
    isPopupButtonDisabled = false
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true)
  }

  const handleClosePopup = () => {
    setIsPopupOpen(false)
  }

  return (
    <>
      <MapTabsTooltip
        width="200px"
        arrowPosition="center"
        message={translate('avatar_short_level', { level: level })}
        isDisabled={isDisabled && !isMobile}
      >
        <div className="item-detail__content-item-detail">
          <div className="item-detail__content-level">
            <div className="item-detail__content-level-text">
              {translate('level')}
            </div>
            <MapLevelCounter level={level} />
          </div>
          <div
            className={`item-detail item-detail--default ${
              isSelected && 'item-detail-selected'
            }`}
            onClick={
              isIntro || isDisabled
                ? () => {
                    if (isDisabled && isMobile) {
                      setShowDisabledPopup(true)
                    }
                  }
                : handleOpenPopup
            }
          >
            <div
              className={`item-detail__wrapper item-detail__wrapper--${cardVariantStyle}`}
            >
              {isSelected && !isIntro && (
                <CheckImg className="item-detail__check" />
              )}
              <div className={`item-detail__image item-detail__image--default`}>
                {avatar.single_image ? (
                  <img
                    src={avatar.single_image}
                    className={`${
                      isDisabled
                        ? 'item-detail-img_disabled'
                        : 'item-detail-img'
                    }`}
                    onDragStart={(event) => event.preventDefault()}
                  />
                ) : (
                  <div className="item-detail__sprite"></div>
                )}
              </div>
            </div>
          </div>

          {isPopupOpen && (
            <ConfirmPopupOptions
              aditionalClass="card-preview"
              size="xs"
              acceptButtonText={popupButtonText}
              onAcceptButtonClick={onButtonClick}
              isAcceptButtonDisabled={isPopupButtonDisabled}
              onClose={handleClosePopup}
              showBtnCancel={false}
            >
              <CardDetails
                name={avatar.name}
                description={avatar.description}
                image={avatar.card_image}
                isSelected={isSelected}
                disabled={false}
              />
            </ConfirmPopupOptions>
          )}
          {showDisabledPopup && (
            <ConfirmPopupOptions
              aditionalClass="card-preview"
              size="xs"
              acceptButtonText={translate('avatar_selected')}
              isAcceptButtonDisabled={true}
              onClose={() => setShowDisabledPopup(false)}
              showBtnCancel={false}
            >
              <CardDetails
                name={avatar.name}
                description={translate('avatar_short_level', { level: level })}
                image={avatar.single_image}
                isSelected={isSelected}
                imageClassName="item-detail-img_disabled"
                level={level}
                disabled={true}
              />
            </ConfirmPopupOptions>
          )}
        </div>
      </MapTabsTooltip>
    </>
  )
}

export default ItemDetail
