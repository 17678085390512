import { useState, useEffect } from 'react'
import { BREAKPOINTS } from '../_practice-components/constants/uiConstants'

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= BREAKPOINTS.smallMobile
  )

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= BREAKPOINTS.smallMobile)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return isMobile
}
