import React, { useEffect, useState } from 'react'
import translate from '../../../i18n/translate'
import './ModalWithTimer.scss'
import PracticeButton from '../../../pages/AppPracticePage/modules/atoms/PracticeButton/PracticeButton'
import { Analysis } from '../../../services/analysisService'

function ModalWithTimer({
  titleTextCode,
  titleTextProperties,
  messageTextCode,
  countdownTextCodeStart,
  countdownTextCodeEnd,
  countdownSeconds,
  acceptTextCode,
  onAccept,
  cancelTextCode,
  onCancel
}) {
  const [countdown, setCountdown] = useState(countdownSeconds)

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Start,
      Analysis.SEGMENT_EVENTS['Log In Pop Up Region Viewed']
    )
  }, [])

  useEffect(() => {
    if (countdown === 0) {
      onAccept()
      return
    }

    const countdownTimer = setInterval(() => {
      setCountdown((countdown) => countdown - 1)
    }, 1000)

    return () => {
      clearInterval(countdownTimer)
    }
  }, [countdown])

  const handleCancel = (event) => {
    onCancel()
  }

  const handleAccept = (event) => {
    Analysis.sendSegmentTrackEvent(
      Analysis.SEGMENT_EVENTS['Log In Pop Up Region Confirmed'],
      {
        region: titleTextProperties.value,
        region_name: titleTextProperties.label,
        tenant: titleTextProperties.url
      }
    )

    onAccept()
  }

  return (
    <div className="modal-with-timer">
      <div className="modal-with-timer_content">
        <div className="modal-with-timer_title">
          {translate(titleTextCode, {
            region: titleTextProperties?.label || 'eu'
          })}
        </div>
        <div className="modal-with-timer_message">
          {translate(messageTextCode)}
        </div>
        <div
          className="modal-with-timer_controls"
          data-testid="modal-cancel-button"
        >
          <PracticeButton
            text={translate(cancelTextCode)}
            type={2}
            action={handleCancel}
          />
          <PracticeButton
            text={translate(acceptTextCode)}
            type={1}
            action={handleAccept}
            data-testid="modal-accept-button"
          />
        </div>

        <div className="modal-with-timer_countdown">
          <span>{translate(countdownTextCodeStart)}</span>
          <span className="modal-with-timer_countdown_end">
            {translate(countdownTextCodeEnd, { countdown: countdown })}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ModalWithTimer
