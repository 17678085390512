import React, { useEffect } from 'react'
import translate from '../../../i18n/translate'
import useMetaTabPets from './useMetaTabPets'
import { setPetsNotification } from '../../../services/rewardsService'
import { Analysis } from '../../../services/analysisService'
import ConfirmPopup from '../ConfirmPopup/ConfirmPopup'
import PopupSelector from '../PopupSelector/PopupSelector'
import ItemDetailPet from '../MetaTabCommons/ItemDetailPet/ItemDetailPet'
import useSound from '../../views/MetaberryView/hooks/useSound'

const MetaTabPets = () => {
  const {
    handleSelectPetAvatar,
    handleBuyPetAvatar,
    petAvatarSelected,
    sortedPetAvatarList,
    userCurrentLevel,
    coinsAccumulated,
    isLoading,
    showBuyErrorPopup,
    setShowBuyErrorPopup,
    showToast,
    setShowToast
  } = useMetaTabPets()

  const { renderSoundFXController, playSoundEffect } = useSound()

  const typePopupSelector = {
    AVATAR: 'avatar',
    PETS: 'pet'
  }

  useEffect(() => {
    // ocLog('sortedPetAvatarList', sortedPetAvatarList)
    let petsUnlocked = 0
    let petsPurchasables = 0
    const tiersLocked = []
    const tiersUnlocked = []
    for (let p = 0, pMax = sortedPetAvatarList.length; p < pMax; p++) {
      const _pet = sortedPetAvatarList[p]
      const petLevel = _pet.petLevel

      if (_pet.isLocked === false) {
        petsUnlocked++
        if (_pet.isOwned === true) petsPurchasables++

        if (tiersUnlocked.indexOf(petLevel) === -1) {
          tiersUnlocked.push(petLevel)
        }
      } else {
        if (tiersLocked.indexOf(petLevel) === -1) {
          tiersLocked.push(petLevel)
        }
      }
    }

    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Pet Selector Page Viewed'],
      {
        tiers_locked: tiersLocked.sort().join(', '),
        tiers_unlocked: tiersUnlocked.sort().join(', '),
        number_pets_available: petsUnlocked,
        number_pets_purchasables: petsPurchasables
      }
    )

    setPetsNotification(false)
  }, [])

  const contentPets = (
    <>
      <div className="meta-tab-pets">
        <div className="meta-tab-pets__content">
          {!isLoading && (
            <>
              <div className="meta-tab-pets__grid">
                {sortedPetAvatarList.map((item) => (
                  <div
                    className="meta-tab-pets__grid-total__item"
                    key={item.guid}
                  >
                    <ItemDetailPet
                      isSelected={petAvatarSelected?.guid === item.guid}
                      item={item}
                      single_image={item.singleImage}
                      onClickSelect={() => {
                        playSoundEffect('petSelected')
                        handleSelectPetAvatar(item.guid)
                      }}
                      onClickBuy={() => {
                        playSoundEffect('spendCash')
                        handleBuyPetAvatar(item.guid)
                      }}
                      isIntro={false}
                      isDisabled={item.petLevel > userCurrentLevel}
                      petLevel={item.petLevel}
                      sizeAvatar={'s'}
                      price={item.price}
                      isPets={true}
                      isLocked={item.isLocked}
                      isOwned={item.isOwned}
                      isExpensive={item.price > coinsAccumulated}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </div>

        {showBuyErrorPopup && (
          <ConfirmPopup
            size="md"
            cancelButtonText={translate('pets_accept')}
            isAcceptButtonDisabled={true}
            onClose={() => setShowBuyErrorPopup(false)}
          >
            <div className="confirm-popup__right">
              <div className="meta-tab-pets-error-popup-title">
                {translate('pets_error_buy_title')}
              </div>
              <div
                className="meta-tab-pets-error-popup-message"
                dangerouslySetInnerHTML={{
                  __html: translate('pets_error_buy_message')
                }}
              ></div>
            </div>
          </ConfirmPopup>
        )}
      </div>
    </>
  )

  return (
    <>
      {renderSoundFXController()}
      <PopupSelector
        isIntro={false}
        handleMainButton={() => {}}
        isLoading={isLoading}
        showToast={showToast}
        setShowToast={setShowToast}
        content={contentPets}
        type={typePopupSelector.PETS}
        typePopupSelector={typePopupSelector}
        showMainButton={false}
        textMainButton={translate('select')}
        level={userCurrentLevel}
        coin={coinsAccumulated}
      />
    </>
  )
}

export default MetaTabPets
