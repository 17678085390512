import React, { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'
import { clearToken, getToken } from '../../services/util'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { store } from '../../store/configureStore'
import {
  setIsGameKeyboardBlocked,
  setIsGameUIVisible,
  setIsGameFromPop
} from '../../store/MetaberrySlice/MetaberrySlice'

function PrivateRoute({ isBlueberryFamily, children, ...rest }) {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    /*
    if (
      history.action === 'POP' &&
      location.pathname !== '/game' &&
      location.pathname !== '/pricing'
    ) {
      if (isLogged || location.pathname !== '/login') {
        store.dispatch(setIsGameFromPop(true))
        store.dispatch(setIsGameUIVisible(false))
        store.dispatch(setIsGameKeyboardBlocked(false))
        history.push('/game')
      }
    }
    */

    if (history.action === 'POP') {
      if (isLogged && location.pathname === '/game') {
        store.dispatch(setIsGameFromPop(true))
        store.dispatch(setIsGameUIVisible(false))
        store.dispatch(setIsGameKeyboardBlocked(false))
      } else if (
        location.pathname !== '/game' &&
        location.pathname !== '/pricing'
      ) {
        if (isLogged /* || location.pathname !== '/login' */) {
          history.push('/game')
        }
      }
    }
  }, [history, location])

  // check if there is token or error in the url and redirect to / with all params
  const url = new URL(window.location.href)
  const ssoToken = url.searchParams.get('token')
  const error = url.searchParams.get('error')
  if (error) {
    clearToken()
  }

  const isLogged = getToken().length > 0 && !ssoToken

  return (
    <Route
      {...rest}
      render={() => {
        return isLogged ? (
          children
        ) : location.pathname !== '/login' ? (
          <Redirect to={!isBlueberryFamily ? '/login' : '/blueberry-family'} />
        ) : (
          children
        )
      }}
    />
  )
}

export default PrivateRoute
