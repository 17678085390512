import getStatus from '../../helpers/getStatus'

export default function extractDefaultLessonData(lesson) {
  const {
    lesson_guid,
    lesson_name,
    lesson_level,
    exp,
    challenges,
    is_available,
    publication_state,
    pieces
  } = lesson

  return {
    lesson_guid,
    lesson_name,
    lesson_level,
    exp,
    challenges,
    is_available,
    publication_state,
    currentPieces: pieces.have,
    totalPieces: pieces.pieces,
    pieces: pieces,
    status: getStatus(lesson)
  }
}
