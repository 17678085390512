import React from 'react'
import RocketOnImg from '../../../../assets/rocket_on.svg'
import RocketOffImg from '../../../../assets/rocket_off.svg'
import './StreakProgress.scss'
import PropTypes from 'prop-types'

const StreakProgress = ({ currentStreak }) => {
  const auxStreak = currentStreak > 4 ? 4 : currentStreak

  return (
    <div className="streak-progress">
      <div className="streak-progress__bullet">
        <img src={auxStreak > 0 ? RocketOnImg : RocketOffImg} alt="" />
      </div>
      <div
        className={`streak-progress__connector ${
          auxStreak > 1
            ? 'streak-progress__connector--on'
            : 'streak-progress__connector--off'
        }`}
      ></div>
      <div className="streak-progress__bullet">
        <img src={auxStreak > 1 ? RocketOnImg : RocketOffImg} alt="" />
      </div>
      <div
        className={`streak-progress__connector ${
          auxStreak > 2
            ? 'streak-progress__connector--on'
            : 'streak-progress__connector--off'
        }`}
      ></div>
      <div className="streak-progress__bullet">
        <img src={auxStreak > 2 ? RocketOnImg : RocketOffImg} alt="" />
      </div>
      <div
        className={`streak-progress__connector ${
          auxStreak > 3
            ? 'streak-progress__connector--on'
            : 'streak-progress__connector--off'
        }`}
      ></div>
      <div className="streak-progress__bullet">
        <img src={auxStreak > 3 ? RocketOnImg : RocketOffImg} alt="" />
      </div>
      <div
        className={`streak-progress__connector ${
          auxStreak > 4
            ? 'streak-progress__connector--on'
            : 'streak-progress__connector--off'
        }`}
      ></div>
      <div className="streak-progress__bullet">
        <img src={auxStreak > 4 ? RocketOnImg : RocketOffImg} alt="" />
      </div>
    </div>
  )
}

StreakProgress.propTypes = {
  currentStreak: PropTypes.number.isRequired
}

export default StreakProgress
