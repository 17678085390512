import { useContext } from 'react'
import { GAMIFICATION_ACTIONS } from '../../config/constants/GamificationConstants'
import { GamificationActivityContext } from './GamificationActivityContext'

export function useGamificationActivityContext() {
  const context = useContext(GamificationActivityContext)

  if (!context) {
    throw new Error(
      'useGamificationActivityContext must be used within an GamificationActivityProvider'
    )
  }

  const { state, dispatch } = context

  const setPointsRewards = (points) => {
    dispatch({
      type: GAMIFICATION_ACTIONS.SET_POINTS_REWARDS,
      payload: {
        points: points
      }
    })
  }

  const setCurrencyCoinRewards = (coins) => {
    dispatch({
      type: GAMIFICATION_ACTIONS.SET_CURRENCY_COIN_REWARDS,
      payload: {
        coins: coins
      }
    })
  }

  const setCurrencyPieceRewards = (pieces) => {
    dispatch({
      type: GAMIFICATION_ACTIONS.SET_CURRENCY_PIECES_REWARDS,
      payload: {
        pieces: pieces
      }
    })
  }

  const setDailyGoalInProgress = (dailyGoalInProgress) => {
    dispatch({
      type: GAMIFICATION_ACTIONS.SET_DAILY_GOAL_IN_PROGRESS,
      payload: {
        dailyGoalInProgress: dailyGoalInProgress
      }
    })
  }

  const setResetActivityGamification = () => {
    dispatch({
      type: GAMIFICATION_ACTIONS.RESET,
      payload: {}
    })
  }

  return {
    pointsReward: state.pointsReward,
    currencyReward: state.currencyReward,
    dailyGoalInProgress: state.dailyGoalInProgress,
    setPointsRewards,
    setCurrencyCoinRewards,
    setCurrencyPieceRewards,
    setResetActivityGamification,
    setDailyGoalInProgress
  }
}
