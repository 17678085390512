import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  piecesAccumulated: 0,
  coinsAccumulated: 0,
  missionsNotification: false,
  petsNotification: false,
  avatarsNotification: false,
  canGetDailyReward: false,
  nextDailyReward: null,
  amountDailyReward: 0,
  cooldownHours: 24
}

export const RewardsSlice = createSlice({
  name: 'Rewards',
  initialState,
  reducers: {
    setPiecesAccumulated: (state, { payload }) => {
      state.piecesAccumulated = payload
    },

    setCoinsAccumulated: (state, { payload }) => {
      state.coinsAccumulated = payload
    },

    setMissionsNotification: (state, { payload }) => {
      state.missionsNotification = payload
    },

    setPetsNotification: (state, { payload }) => {
      state.petsNotification = payload
    },

    setAvatarsNotification: (state, { payload }) => {
      state.avatarsNotification = payload
    },

    setDailyAccessReward: (state, { payload }) => {
      state.canGetDailyReward = payload.canGetDailyReward
      state.nextDailyReward = payload.nextDailyReward
      state.amountDailyReward = payload.amountDailyReward
      state.cooldownHours = payload.cooldownHours
    },

    setCanGetDailyReward: (state, { payload }) => {
      state.canGetDailyReward = payload
    },

    resetRewards: () => initialState
  }
})

// Action creators are generated for each case reducer function
export const {
  setPiecesAccumulated,
  setCoinsAccumulated,
  resetRewards,
  setPetsNotification,
  setAvatarsNotification,
  setDailyAccessReward,
  setCanGetDailyReward
} = RewardsSlice.actions

export default RewardsSlice.reducer
