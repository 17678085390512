import React from 'react'
import './toastDrawer.scss'
import checkImg from '../../../assets/icons/check-circle.svg'
import Drawer from '../../atoms/Drawer/Drawer'

function ToastDrawer({ text, handleClose }) {
  return (
    <Drawer direction="bottom" onClose={handleClose} showCloseButton={false}>
      <div className="toast-drawer__container">
        <div className="toast-drawer__icon">
          <img src={checkImg} alt="" />
        </div>
        <div className="toast-drawer__content">{text}</div>
      </div>
    </Drawer>
  )
}

export default ToastDrawer
