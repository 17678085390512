import React from 'react'
import './CardDetails.scss'
import translate from '../../../../i18n/translate'
import MapLevelCounter from '../../MapLevelBadge/components/MapLevelCounter/MapLevelCounter'
import { ReactComponent as Coins } from '../../../../assets/img/pets_coins.svg'

const CardDetails = ({
  name,
  description,
  price,
  image,
  isLocked,
  isExpensive,
  isOwned,
  imageClassName,
  isSelected,
  level = 0,
  disabled = false
}) => {
  return (
    <>
      <div className={'card-details__container'}>
        {name && (
          <div
            className={`card-details__name-container ${
              price && 'card-details__name-container-pet'
            } ${disabled ? 'card-details__name-container--disabled' : ''}`}
          >
            {disabled && (
              <div className="card-details__content-level">
                <div className="card-details__content-level-text">
                  {translate('level')}
                </div>
                <MapLevelCounter level={level} />
              </div>
            )}
            <div
              className={`card-details__name ${
                imageClassName ? 'card-details__name--hidden' : ''
              }`}
            >
              {name}
            </div>

            <div className="card-details__image">
              {image ? (
                <div
                  className={`card-details__image-sprite ${
                    imageClassName || ''
                  }`}
                  style={{
                    backgroundImage: `url(${image})`,
                    filter: isLocked && !isOwned && 'contrast(0)'
                  }}
                ></div>
              ) : (
                <div className="card-details__image-sprite"></div>
              )}
            </div>
            {price && !isOwned && (
              <div className="card-details__image-coins">
                <Coins />
                <span className="card-details__image-coins-price">{price}</span>
              </div>
            )}
          </div>
        )}

        {description && (
          <div
            className={`card-details__description ${
              price && !isOwned && 'card-details__description-coins'
            }`}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </div>
    </>
  )
}

export default CardDetails
